import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import { makeStyles } from "@material-ui/core/styles";
import rehypeReact from "rehype-react";

// <-- swiper start
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery, useTheme } from "@material-ui/core";

import useAdvert from "../hooks/useAdvert";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./swiper.css";
import poster_image from "../assets/images/mpv-shot0002.png";

import { FreeMode, Navigation, Thumbs } from "swiper";

// swiper end -->

import { Video } from "gatsby-video";

import { Button } from "gatsby-theme-material-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "@material-ui/core/Grid";
import cx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import CardMedia from "@material-ui/core/CardMedia";
import TextInfoContent from "@mui-treasury/components/content/textInfo";

import Layout from "../components/Layout";
import Container from "../components/Container";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2em",
  },
  body: {
    textAlign: "justify",
  },
  advert: {},
  advertImg: {
    objectFit: "contain",
  },
  action: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "200px",
  },
}));

const TransportPage = ({
  data: {
    allMarkdownRemark: { edges: content },
  },
}) => {
  const styles = useStyles();
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const advert = useAdvert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Layout pageName="transportation">
      <Helmet>
        <title>Transport Services</title>
      </Helmet>

      <Container maxWidth="md" type="content">
        <Grid xs={12} className={cx(styles.advert)}>
          <Link to={"/grouptrips"}>
            <CardMedia
              className={cx(styles.advertImg)}
              image={isMobile ? advert.sm : advert.lg}
              component="img"
              alt="advertisement"
            />
          </Link>
        </Grid>

        <Grid container className={styles.root} spacing={4}>
          {content.map((c) => (
            <>
              {/* xs, sm, md, lg, and xl. */}
              <Grid item xs={12} md={6} key={`g-content`}>
                <TextInfoContent
                  bodyProps={{ className: styles.body }}
                  overline={c.node.frontmatter.overline}
                  heading={c.node.frontmatter.title}
                  body={renderAst(c.node.htmlAst)}
                />
                <Button
                  className={styles.action}
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.$chatwoot.reset();
                    window.$chatwoot.setCustomAttributes({
                      inquiry: "transportation",
                    });
                    window.$chatwoot.toggle("open");
                  }}
                >
                  Start Booking
                </Button>
              </Grid>
              <Grid item xs={12} md={6} key={`g-image`}>
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.image,
                      )}
                      alt={"topic image"}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.image2,
                      )}
                      alt={"topic image"}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.image3,
                      )}
                      alt={"topic image"}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Video
                      poster={poster_image}
                      playsinline
                      controls
                      sources={[
                        c.node.frontmatter.video.remux
                          .webm,
                        c.node.frontmatter.video.remux
                          .mp4,
                      ]}
                    />
                  </SwiperSlide>
                </Swiper>

                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={5}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.image,
                      )}
                      alt="topic image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.image2,
                      )}
                      alt={"topic image"}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.image3,
                      )}
                      alt={"topic image"}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      image={getImage(
                        c.node.frontmatter.videoCover,
                      )}
                      alt="topic image"
                    />
                  </SwiperSlide>
                </Swiper>
              </Grid>
            </>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};

export default TransportPage;

export const TransportPageQuery = graphql`
    query TransportPageQuery {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "page-transportation" } } }
            limit: 1
        ) {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        title
                        overline
                        url
                        videoCover {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                        video {
                            remux: childVideoFfmpeg {
                                webm: transcode(
                                    codec: "libvpx-vp9"
                                    audioCodec: "libopus"
                                    maxWidth: 900
                                    maxHeight: 480
                                    fileExtension: "webm"
                                    outputOptions: ["-crf 20", "-b:v 0"]
                                ) {
                                    width
                                    src
                                    presentationMaxWidth
                                    presentationMaxHeight
                                    originalName
                                    height
                                    fileExtension
                                    aspectRatio
                                }
                                mp4: transcode(
                                    codec: "libx264"
                                    audioCodec: "libmp3lame"
                                    maxWidth: 900
                                    maxHeight: 480
                                    fileExtension: "mp4"
                                    options: [
                                        ["-profile:v", "main"]
                                        ["-pix_fmt", "yuv420p"]
                                    ]
                                    outputOptions: ["-movflags faststart"]
                                ) {
                                    width
                                    src
                                    presentationMaxWidth
                                    presentationMaxHeight
                                    originalName
                                    height
                                    fileExtension
                                    aspectRatio
                                }
                            }
                        }
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 1008,
                                    width: 756,
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                        image2 {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 1008,
                                    width: 756,
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                        image3 {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 1008,
                                    width: 756,
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
