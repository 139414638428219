import { graphql, useStaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

const useAdvert = () => {
  const query = graphql`
    query AdvertQuery {
      lg: allFile(
          filter: { sourceInstanceName: { eq: "adverts-lg" } }, limit: 1
      ) {
          edges {
              node {
                  childImageSharp {
                      gatsbyImageData
                  }
                  name
              }
          }
      }
      sm: allFile(
          filter: { sourceInstanceName: { eq: "adverts-sm" } }, limit: 1
      ) {
          edges {
              node {
                  childImageSharp {
                      gatsbyImageData
                  }
                  name
              }
          }
      }
    }
  `;

  const result = useStaticQuery(query);
  const {
    lg: { edges: [{ node: lg }] },
    sm: { edges: [{ node: sm }] },
  } = result;
  return {
    ready: true,
    lg: getSrc(lg.childImageSharp),
    sm: getSrc(sm.childImageSharp),
  };
};

export default useAdvert;
